import Notification, { Acceptance, Offer } from '../../models/Notification'
import Post from '../../models/Post'
import Poster from '../../models/Poster'
import Registration from '../../models/Registration'
import Backend, { LoginProps, LogoutProps } from '../backend'
import DummyLogin from './login'
import MatchedUser from '../../models/MatchedUser'


import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithRedirect,getRedirectResult,signInWithEmailAndPassword, UserCredential, onAuthStateChanged} from "firebase/auth"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { auth, db, provider } from '../../firebase-config'
import { doc, collection, addDoc, Firestore, Timestamp, getDoc, getDocs, QueryDocumentSnapshot, DocumentData, DocumentSnapshot, updateDoc, arrayUnion, query, collectionGroup, where, deleteDoc, setDoc, getDocFromCache, enableIndexedDbPersistence, orderBy, enableMultiTabIndexedDbPersistence } from 'firebase/firestore'
import DummyLogout from './logout'

class DevBackend implements Backend {

    // Used to simulate latency/server processing
    wait(): Promise<void> {
        return new Promise((res, rej) => {
            setTimeout(() => res(), this.delay)
        })
    }

    // Implementation
    db: Firestore
    authenticated: boolean
    delay: number

    constructor(
        delay: number,
        authenticated: boolean,
        userId: string
    ) {
        console.log("constructing backend")
        this.db = db
        this.delay = delay
        this.authenticated = authenticated
        // Needed for callback
        this.renderLogin = this.renderLogin.bind(this)
        this.renderLogout = this.renderLogout.bind(this)

        console.log("enabling persistence")
        enableMultiTabIndexedDbPersistence(db)
        .catch((err) => {
            if (err.code == 'failed-precondition') {
                // multi-tab not supported in another tab
                console.error("persistence precondition failed")
            } else if (err.code == 'unimplemented') {
                // The current browser does not support all of the
                // features required to enable multi-tab persistence
                console.error("persistence unimplemented")
                enableIndexedDbPersistence(db)
                .catch((err) => {
                    if (err.code == 'failed-precondition') {
                        // Multiple tabs open, persistence can only be enabled
                        // in one tab at a a time.
                        console.error("persistence precondition failed")
                    } else if (err.code == 'unimplemented') {
                        // The current browser does not support all of the
                        // features required to enable persistence
                        console.error("persistence unimplemented")
                    }
                });
            }
        });
        console.log("constructed backend")
    }
    
    async initialize() {
        console.log("initializing backend")
        return true
    }

    async register(data: Registration): Promise<void> {
        console.log("registering:")
        console.log(data)
        try {
            const userDocRef = await setDoc(doc(this.db, "dev-posters", data.userId), {
                userId: data.userId,
                userCreationTime: Timestamp.now(), 
                username: data.username,
                year: data.year,
                name: data.name,
                interests: data.interests,
                email: data.email,
                imageUrls: [data.profilePicture], // users can add more later
                bio: '', // users add bio later - reduce initial registration burden
                contact: { 
                    phoneNumber: data.contact.phoneNumber, 
                    instagram: data.contact.instagram,
                    snapchat: data.contact.snapchat,
                }
            })
            console.warn("Firestore - set poster (register)")
        } catch (e) {
            console.error("Failed to write document: %s", e)
        }
    }

    async getPosterInformation(userId: string) {
        const userDocRef = doc(this.db, "dev-posters", userId)
        var userDocSnap: DocumentSnapshot<DocumentData>
        try {
            userDocSnap = await getDocFromCache(userDocRef)
            console.warn("Firestore - cache hit! (getPosterInformation)")
        } catch (e) {
            userDocSnap = await getDoc(userDocRef)
            console.warn("Firestore - get poster (getPosterInformation)")
        }

        if (userDocSnap.exists()) {
            const data = userDocSnap.data()
            const poster: Poster = {
                id: userDocSnap.id,
                username: data.username,
                name: data.name,
                interests: data.interests,
                imageUrls: data.imageUrls,
                bio: data.bio,
                year: data.year,
                contact: data.contact,
            }
            return poster
        } else {
            console.log("Couldn't find user %s", userId)
            return undefined
        }
    }

    async submitPost(
        description: string,
        time: Date
    ): Promise<void> {
        try {
            const postDocRef = await addDoc(collection(this.db, "dev-posts"), {
                description: description,
                eventTime: time,
                posterId: auth.currentUser!.uid,
                requested: [],
                accepted: [],
                postCreationTime: Timestamp.now(), 
            })
            console.warn("Firestore - set post (submitPost)")
            console.log("Document written with ID %s", postDocRef.id)
        } catch (e) {
            console.error("Failed to write document: %s", e)
        }
    }

    async postDocSnapToPost(
        postDocSnap: DocumentSnapshot<DocumentData> | 
                     QueryDocumentSnapshot<DocumentData>
    ) {
        if (postDocSnap.exists()) {
            const data = postDocSnap.data()
            const poster = await this.getPosterInformation(data.posterId)
            const requestingUsers = (await Promise.all(data.requested.map(
                (requesterId: string) => this.getPosterInformation(requesterId) 
            ))).filter(
                (poster: Poster) => { return poster != undefined }
            )
            if (poster == undefined) {
                console.error("Couldn't find poster %s", data.posterId)
                return undefined
            } else {
                const post: Post = {
                    id: postDocSnap.id,
                    description: data.description,
                    time: data.eventTime.toDate(),
                    endTime: data.eventEndTime ? data.eventEndTime.toDate : undefined,
                    posterId: data.posterId,
                    posterUsername: poster.username,
                    posterYear: poster.year,
                    posterName: poster.name,
                    posterInterests: poster.interests,
                    posterImageUrls: poster.imageUrls,
                    posterBio: poster.bio ? poster.bio : "Another student at our school!",
                    posterNumber: poster.contact["phoneNumber"],
                    requested: data.requested.includes(auth.currentUser?.uid),
                    requestingUsers: requestingUsers,
                }
                return post
            }
        } else {
            console.error("Couldn't find post " + postDocSnap)
            return undefined
        }    
    }

    async getPost(postId: string) {
        const postDocRef = doc(this.db, "dev-posts", postId)
        const postDocSnap = await getDoc(postDocRef)
        console.warn("Firestore - get post (getPost)")

        return this.postDocSnapToPost(postDocSnap)
    }

    async getListAfterAuth(page: number, amount: number) {
        const q = query(
            collection(this.db, "dev-posts"), 
            orderBy("eventTime", "desc"))
        const postsSnapshot = await getDocs(q)
        console.warn("Firestore - get posts (getListAfterAuth)")
        const posts = await Promise.all(postsSnapshot.docs
            .slice(amount * page, amount * (page + 1))
            .map(doc => this.postDocSnapToPost(doc)!))
        return posts.filter((post): post is Post => { return post != undefined })
    }

    async listPosts(page: number, amount: number) {
        return this.getListAfterAuth(page, amount)
    }

    async submitRequest(postId: string): Promise<void> {
        const user = await this.getPosterInformation(auth.currentUser!.uid)
        if (user == undefined) {
            console.error("User undefined; cannot submit request")
            return
        }
        
        const postDocRef = doc(this.db, "dev-posts", postId)
        const postDocSnap = await getDoc(postDocRef)
        console.warn("Firestore - get post (submitRequest)")

        if (postDocSnap.exists()) {
            const postData = postDocSnap.data()

            try {
                const notifDocRef = await addDoc(collection(
                    this.db, "dev-posters", postData.posterId, "dev-notifications"
                ), {
                    type: 'offer',
                    username: user!.username,
                    iconUrl: user!.imageUrls[0],
                    description: postData.description,
                    senderId: auth.currentUser!.uid,
                    recipientId: postData.posterId,
                    postId: postId,
                    sendTime: Timestamp.now(), 
                })
                console.warn("Firestore - set notif (submitRequest)")
                await updateDoc(notifDocRef, {notifId: notifDocRef.id})
                console.warn("Firestore - update notif (submitRequest)")

                await updateDoc(postDocRef, {
                    requested: arrayUnion(auth.currentUser!.uid),
                })    
                console.warn("Firestore - update post (submitRequest)")
            } catch (e) {
                console.error("Failed to write document: %s", e)
            }
        } else {
            console.error("Coudln't find post %s", postId)
        }

    }

    notifDocSnapToNotification(
        notifDocSnap: QueryDocumentSnapshot<DocumentData>
    ) {
        if (notifDocSnap.exists()) {
            const data = notifDocSnap.data()
            if (data.type == 'offer') {
                const notif: Offer = {
                    id: notifDocSnap.id,
                    type: data.type,
                    username: data.username,
                    iconUrl: data.iconUrl,
                    description: data.description,
                    senderId: auth.currentUser!.uid,
                    recipientId: data.recipientId,
                    postId: data.postId,
                    sendTime: data.sendTime,
                }
                return notif
            } else if (data.type == 'acceptance') {
                const notif: Acceptance = {
                    id: notifDocSnap.id,
                    type: data.type,
                    match: data.match,
                    iconUrl: data.iconUrl,
                    description: data.description,
                    eventDate: data.eventDate,
                    senderId: auth.currentUser!.uid,
                    recipientId: data.recipientId,
                    postId: data.postId,
                    sendTime: data.sendTime,
                }
                return notif
            }
        } else {
            console.log("Couldn't find notification " + notifDocSnap)
            return undefined
        }
    }

    async listNotifications() {
        console.log("listing notifs for %s", auth.currentUser!.uid)
        const notifSnapshot = await getDocs(collection(
            this.db, "dev-posters", auth.currentUser!.uid, "dev-notifications"
        ))
        console.warn("Firestore - get notifs (listNotifications)")
        return notifSnapshot.docs.map(
            doc => this.notifDocSnapToNotification(doc)!
        )
    }

    async notifyAccept(
        notifSnapshot: QueryDocumentSnapshot<DocumentData>
    ): Promise<Acceptance | undefined> {
        const notifData = notifSnapshot.data()
        const post = await this.getPost(notifData.postId)
        console.warn("Firestore - get post (notifyAccept)")
        if (post == undefined) {
            console.error("Post %s not found", notifData.postId)
            return undefined
        }

        const acceptingUser = await this.getPosterInformation(auth.currentUser!.uid)
        if (acceptingUser == undefined) {
            console.error("Accepting user undefined; cannot submit request")
            return undefined
        }

        const acceptedUser = await this.getPosterInformation(notifData.senderId)
        if (acceptedUser == undefined) {
            console.error("Accepting user undefined; cannot submit request")
            return undefined
        }

        const match: MatchedUser = {
            userId: acceptingUser.id,
            username: acceptingUser.username,
            year: acceptingUser.year,
            name: acceptingUser.name,
            contact: {
                email: acceptingUser.contact["email"] || "",
                phoneNumber: acceptingUser.contact["phoneNumber"] || "",
                instagram: acceptingUser.contact["instagram"] || "",
                snapchat: acceptingUser.contact["snapchat"] || "",
            }
        }

        try {
            const notifDocRef = await addDoc(collection(
                this.db, "dev-posters", notifData.senderId, "dev-notifications"
            ), {
                type: 'acceptance',
                match: match,
                iconUrl: acceptingUser!.imageUrls[0],
                description: notifData.description,
                eventDate: post!.time,
                senderId: acceptingUser.id,
                recipientId: acceptedUser.id,
                postId: notifData.postId,
                sendTime: Timestamp.now(), 
            })
            console.log("Notification written with ID %s", notifDocRef.id)
            await updateDoc(notifDocRef, {notifId: notifDocRef.id})

            await updateDoc(doc(this.db, "dev-posts", notifData.postId), {
                accepted: arrayUnion(auth.currentUser!.uid),
            })    
            console.warn("Firestore - set notif (notifyAccept)")
            console.warn("Firestore - update notif (notifyAccept)")
            console.warn("Firestore - update post (notifyAccept)")
        } catch (e) {
            console.error("Failed to write document: %s", e)
            return undefined
        }

        const selfMatch: MatchedUser = {
            userId: acceptedUser.id,
            username: acceptedUser.username,
            year: acceptedUser.year,
            name: acceptedUser.name,
            contact: {
                email: acceptedUser.contact["email"] || "",
                phoneNumber: acceptedUser.contact["phoneNumber"] || "",
                instagram: acceptedUser.contact["instagram"] || "",
                snapchat: acceptedUser.contact["snapchat"] || "",
            }
        }

        try {
            const selfNotifDocRef = await addDoc(collection(
                this.db, "dev-posters", acceptingUser.id, "dev-notifications"
            ), {
                type: 'acceptance',
                match: selfMatch,
                iconUrl: acceptedUser!.imageUrls[0],
                description: notifData.description,
                eventDate: post!.time,
                senderId: acceptedUser.id,
                recipientId: acceptingUser.id,
                postId: notifData.postId,
                sendTime: Timestamp.now(), 
            })
            console.log("Notification written with ID %s", selfNotifDocRef.id)
            await updateDoc(selfNotifDocRef, {notifId: selfNotifDocRef.id})

            await updateDoc(doc(this.db, "dev-posts", notifData.postId), {
                accepted: arrayUnion(auth.currentUser!.uid),
            })
            console.warn("Firestore - set notif (notifyAccept)")
            console.warn("Firestore - update notif (notifyAccept)")
            console.warn("Firestore - update post (notifyAccept)")

            const accepterNotif: Acceptance = {
                id: selfNotifDocRef.id,
                type: 'acceptance',
                match: selfMatch,
                iconUrl: acceptedUser!.imageUrls[0],
                description: notifData.description,
                eventDate: post!.time,
                senderId: acceptedUser.id,
                recipientId: acceptingUser.id,
                postId: notifData.postId,
                sendTime: Timestamp.now(), 
            }

            return accepterNotif
        } catch (e) {
            console.error("Failed to write document: %s", e)
            return undefined
        }
    }

    async acceptRequest(id: string): Promise<Acceptance | undefined> {
        const notifsWithId = query(
            collectionGroup(this.db, "dev-notifications"),
            where('notifId', '==', id)
        )
        console.warn("Firestore - query (acceptRequest)")
        const notifsSnapshot = await getDocs(notifsWithId)
        console.warn("Firestore - get notifs (acceptRequest)")

        if (notifsSnapshot.size == 0) {
            console.error("Notification %s not found", id)
        } else if (notifsSnapshot.size > 1) {
            console.error(
                "Multiple notifs with id %s found! %s",
                id,
                notifsSnapshot.docs
            )
        } else {
            var acceptNotifSnapshot: QueryDocumentSnapshot<DocumentData>
            notifsSnapshot.forEach(
                d => {
                    acceptNotifSnapshot = d
                    deleteDoc(doc(
                        this.db, 
                        "dev-posters", 
                        auth.currentUser!.uid, 
                        "dev-notifications", 
                        id,
                    ))
                }
            )
            return this.notifyAccept(acceptNotifSnapshot!).then((notif) => {
                return notif!
            })
        }
    }

    async rejectRequest(id: string): Promise<void> {
        const notifsWithId = query(
            collectionGroup(this.db, "dev-notifications"),
            where('notifId', '==', id)
        )
        const notifsSnapshot = await getDocs(notifsWithId)
        if (notifsSnapshot.size == 0) {
            console.error("Notification %s not found", id)
        } else if (notifsSnapshot.size > 1) {
            console.error(
                "Multiple notifs with id %s found! %s",
                id,
                notifsSnapshot.docs
            )
        } else {
            notifsSnapshot.forEach(
                d => {
                    deleteDoc(doc(
                        this.db, 
                        "dev-posters", 
                        auth.currentUser!.uid, 
                        "dev-notifications", 
                        id,
                    ))
                }
            )
        }
    }

    async handleLogin(props: LoginProps, res: UserCredential) {
        const user = res.user //firebase user instance
        console.log('firebase user instance')
        console.log(user)
        console.log(user.uid) //store users by their uid. 
        props.onResult(true) 
        this.authenticated = true 
        console.log('this')
        console.log(this)
        console.log('get current: ')
        console.log(auth.currentUser)
        const userQuery = query(collection(this.db, "dev-posters"), where("userId", "==", user.uid));
        const users = await getDocs(userQuery);
        if (users.docs.length == 0) {
            console.error("user not registered!")
        }
    }

    renderLogin(props: LoginProps) {
        console.log(this)
        console.log('showing login')
        console.log("is auth: %s", this.authenticated)
        const callback = () => {
            signInWithRedirect(auth,provider)
            console.log(this)
        }
        getRedirectResult(auth).then((res) => {  // res is the user credentials
            try{
                if(res!=null){ // if we have no user logged, res = null
                    this.handleLogin(props, res)
                }
            }
             catch(error){
                console.log("There was an error with getRedirectResult.")
                console.log(error)
            }
        })

        return DummyLogin({ callback }) 
    }

    // lets make a logout function
    renderLogout (props: LogoutProps) {
        const callback = () => {
            auth.signOut()
            console.log("logout")
            props.onResult(false) 
            this.authenticated = false
            console.log(this)
        }
        return DummyLogout({ callback }) 

    };
}

export default DevBackend
