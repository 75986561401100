import layoutStyles from '../../../components/layout/LayoutStyles'

function Loading() {
    return (
        <div className={layoutStyles.main}>
            <p>Loading...</p>
        </div>
    )
}

export default Loading
