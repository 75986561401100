import layoutStyles from '../../../components/layout/LayoutStyles'
import LinkButton from '../../../components/button/LinkButton'

function Success() {
    return (
        <div className={layoutStyles.main}>
            <h2>Registration success!</h2>
            <LinkButton to="/login">Log In</LinkButton>
        </div>
    )
}

export default Success
