import { isPropertySignature } from 'typescript'
import TextButton from '../../components/button/TextButton'


interface DummyLogoutProps {
    callback: () => void
}

function DummyLogout(props: DummyLogoutProps) {
    return <TextButton onClick={props.callback}>Log Out</TextButton> 
}

export default DummyLogout
