import { FormEvent, useState } from 'react'
import FormSubmitButton from '../../../components/button/FormSubmitButton'
import styles from './RegisterStep.module.css'
import layoutStyles from '../../../components/layout/Layout.module.css'

interface EmailProps {
    callback: (
        name: string,
        username: string,
        phoneNumber: string,
        instagram: string,
        snapchat: string,
        year: string
    ) => void
}

function Profile(props: EmailProps) {
    const [name, setName] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [instagram, setInstagram] = useState<string>('')
    const [snapchat, setSnapchat] = useState<string>('')
    const [year, setYear] = useState<string>('')
    const [errorText, setErrorText] = useState<string>('')

    function onFormSubmit(e: FormEvent) {
        props.callback(name, username, phoneNumber, instagram, snapchat, year)
    }

    return (
        <div className={layoutStyles.main}>
            <form onSubmit={onFormSubmit}>
                <label htmlFor="nameInput">Name*</label>
                <input
                    required
                    id="nameInput"
                    onChange={(e) => setName(e.target.value)}></input>
                <label htmlFor="usernameInput">Username*</label>
                <input
                    required
                    id="usernameInput"
                    onChange={(e) => setUsername(e.target.value)}></input>
                <p>
                    Let us know how you want to coordinate! 
                    This can only be seen by other Columbia/Barnard people with 
                    whom you might be able to share a ride.
                </p>
                <label htmlFor="phoneNumberInput">Phone Number*</label>
                <input
                    required
                    id="phoneNumberInput"
                    onChange={(e) => setPhoneNumber(e.target.value)}></input>
                <label htmlFor="instagram">Instagram</label>
                <input
                    id="instagram"
                    onChange={(e) => setInstagram(e.target.value)}></input>
                <label htmlFor="snapchatInput">Snapchat</label>
                <input
                    id="snapchatInput"
                    onChange={(e) => setSnapchat(e.target.value)}></input>
                <label htmlFor="yearInput">Year</label>
                <input
                    id="yearInput"
                    onChange={(e) => setYear(e.target.value)}></input>
                <FormSubmitButton>Next</FormSubmitButton>
            </form>
        </div>
    )
}

export default Profile
