import { Link, To } from 'react-router-dom'
import styles from './Button.module.css'

interface LinkButtonProps {
    to: To
    children: String
    smallMargins?: boolean
    shrink?: boolean
    disabled?: boolean
}

function LinkButton(props: LinkButtonProps) {
    return (
        <Link
            to={props.to}
            className={`
                ${styles.button} 
                ${props.smallMargins ? styles.smallMargins : ''} 
                ${props.shrink ? styles.shrink : ''} 
                ${props.disabled ? styles.disabled : ''}
            `}>
            {props.children}
        </Link>
    )
}

export default LinkButton
