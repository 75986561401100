import { useContext, useEffect, useState } from 'react'
import { BackendContext } from '../../api/backend'
import NotificationModel from '../../models/Notification'
import layoutStyles from '../../components/layout/LayoutStyles'
import TopBar from '../../components/topbar/TopBar'
import Notification from '../../components/notification/Notification'
import { MdHome } from 'react-icons/md'
import LinkIconButton from '../../components/icon-button/LinkIconButton'
import { auth } from '../../firebase-config'
import { useNavigate } from 'react-router-dom'
import { wait } from '@testing-library/user-event/dist/utils'

function Notifications() {
    const navigate = useNavigate()
    const api = useContext(BackendContext)
    const [notifications, setNotifications] = useState<NotificationModel[]>()

    // Require login
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            user ? Function.prototype() : navigate('/login')
        })
    })

    useEffect(() => {
        api?.listNotifications().then(
            (notifications) => notifications && setNotifications(notifications)
        )
    }, [api])

    function deleteCallback(id: string) {
        setNotifications((notifications) => {
            if (!notifications) return
            let notificationIndex = notifications.findIndex(
                (notification) => notification.id === id
            )
            if (notificationIndex !== undefined) {
                let newNotifications = [...notifications]
                newNotifications.splice(notificationIndex, 1)
                return newNotifications
            }
            return notifications
        })
    }

    return (
        <div className={layoutStyles.outer}>
            <TopBar>
                <LinkIconButton to="/feed">
                    <MdHome />
                </LinkIconButton>
            </TopBar>
            <div className={layoutStyles.inner}>
                <h1>Notifications!</h1>
                <div className={`${layoutStyles.main} ${layoutStyles.rounded}`}>
                    {notifications ? (
                        notifications.map((e) => (
                            <Notification
                                notification={e}
                                key={e.id}
                                deleteCallback={deleteCallback}
                            />
                        ))
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Notifications
