import { FormEvent, useContext, useState } from 'react'
import FormSubmitButton from '../../../components/button/FormSubmitButton'
import styles from './RegisterStep.module.css'
import layoutStyles from '../../../components/layout/LayoutStyles'
import Backend, { BackendContext } from '../../../api/backend'

interface AuthProps {
    callback: () => void
}

function Auth(props: AuthProps) {
    const api = useContext(BackendContext)
    const [errored, setErrored] = useState(false)

    function onAuthentication(success: boolean) {
        if (success) {
            props.callback()
        } else {
            setErrored(true)
        }
    }

    return (
        <>
            <div>
                {api && (
                    <api.renderLogin
                        onResult={onAuthentication}></api.renderLogin>
                )}
                {errored && <p>Something went wrong, please try again</p>}
            </div>
        </>
    )
}

export default Auth
