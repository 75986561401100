import LinkButton from '../../components/button/LinkButton'
import TopBar from '../../components/topbar/TopBar'

import styles from './Feed.module.css'
import layoutStyles from '../../components/layout/LayoutStyles'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { BackendContext } from '../../api/backend'
import Post from '../../models/Post'
import PostCard from '../../components/post-card/PostCard'
import IconButton from '../../components/icon-button/IconButton'
import { MdAdd, MdNotifications } from 'react-icons/md'
import LinkIconButton from '../../components/icon-button/LinkIconButton'
import { Navigate } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from '../../firebase-config'


interface LoadedPages {
    loadedPages: number
    requestedPages: number
    posts: Post[]
    done: boolean
}

function Feed() {
    const navigate = useNavigate()

    const api = useContext(BackendContext)
    const [data, setData] = useState<LoadedPages>({
        loadedPages: 0,
        requestedPages: 1,
        posts: [],
        done: false,
    })

    const scrollDivRef = useRef<HTMLDivElement>(null)

    // Queues an additional page
    const queuePage = useCallback(() => {
        // Check to see if new data has already been requested
        let loadedPages = data.loadedPages
        setData((currentData) => {
            // Check to see if new data has already been added or requested before this callback was triggered.
            if (
                currentData.loadedPages > loadedPages ||
                loadedPages < currentData.requestedPages
            ) {
                return currentData
            }
            return {
                ...currentData,
                requestedPages: currentData.loadedPages + 1,
            }
        })
    }, [data.loadedPages])

    // If the posts don't fill up the screen, add some more
    useEffect(() => {
        let div = scrollDivRef.current
        if (!div) return
        if (!data.done && div.scrollHeight >= div.clientHeight) {
            queuePage()
            console.log("queueing page! (1)")
        }
    }, [data.posts.length, data.done, queuePage])

    // This effect loads new pages on request.
    useEffect(() => {
        console.log("effect called! data.loadedPages: %s data.requestedPages: %s", data.loadedPages, data.requestedPages)
        let newPage = data.loadedPages
        console.log("newPage: %s / data.requestedPages: %s", newPage, data.requestedPages)
        if (newPage >= data.requestedPages) return
        console.log("api: %s", api)
        api?.listPosts(newPage, 5).then((newPosts) => {
            console.log("listed posts!")
            if (!newPosts) {
                return
            }
            // The setData call will retrigger the effect, creating a loop until requestedPages matches loadedPages.
            setData((currentData) => {
                if (currentData.loadedPages > newPage) {
                    return currentData
                }
                return {
                    loadedPages: newPage + 1,
                    requestedPages: currentData.requestedPages,
                    posts: [...currentData.posts, ...(newPosts as Post[])],
                    done: newPosts.length === 0,
                }
            })
        })
    }, [api, data.requestedPages, data.loadedPages])

    // If the user scrolls to the bottom
    function onScroll(e: React.UIEvent<HTMLDivElement>) {
        let div = e.currentTarget
        // https://stackoverflow.com/a/32283147
        if (div.scrollHeight - div.scrollTop <= div.clientHeight) {
            queuePage()
            console.log("queueing page! (2)")
        }
    }

    return (
        <div className={layoutStyles.outer}>
            <TopBar>
                <h1>helloours.com</h1>
                <LinkIconButton to="/notifications">
                    <MdNotifications />
                </LinkIconButton>
                <LinkIconButton to="/create">
                    <MdAdd />
                </LinkIconButton>
            </TopBar>
            
            <div className={layoutStyles.inner}>
                <div className={styles.tagline}>
                    <a>Find classmates to split a ride with!</a>
                </div>
                <div
                    className={`${layoutStyles.main} ${layoutStyles.rounded}`}
                    ref={scrollDivRef}
                    onScroll={onScroll}
                    onWheel={onScroll}
                    onTouchMove={onScroll}>
                    <div className={styles.intro}>
                        <p>
                            Welcome to our exclusive rideshare-coordinating platform! 
                            Click the plus icon in the top right corner to add a post
                            of your own. You can also join other people's posts, and we'll
                            try to arrange a match :)
                        </p>
                        <br></br>
                        <p>
                            If you end up riding together, send Madeline (650-422-1187)
                            a pic of your group and <b>we'll cover $5 of your trip!</b>
                        </p>
                    </div>
                    {data.posts.map((e) => (
                        <PostCard post={e} key={e.id} interactive />
                    ))}
                    <div className={styles.logout}>
                        {api && (
                            <api.renderLogout
                                onResult={() => 
                                    navigate('/') //navigates back to home when clicked, logging out
                                }></api.renderLogout>
                        )}
                    </div>
                </div>
            </div> 
           
        </div>
    )
}

export default Feed
