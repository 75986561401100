import LinkButton from '../../components/button/LinkButton'
import TopBar from '../../components/topbar/TopBar'

import styles from './Login.module.css'
import layoutStyles from '../../components/layout/LayoutStyles'
import { useContext, useEffect } from 'react'
import { BackendContext } from '../../api/backend'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase-config'

function Login() {
    const api = useContext(BackendContext)
    const navigate = useNavigate()

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            user ? navigate('/feed') : Function.prototype()
        })
    })

    return (
        <div className={layoutStyles.outer}>
            <TopBar></TopBar>
            <div className={layoutStyles.inner}>
                <h1>Login</h1>
                <p>
                    Log in using your Columbia/Barnard email!
                </p>
                <div className={layoutStyles.main}>
                    {api && (
                        <api.renderLogin
                            onResult={() => {//callback function makes this happen
                                console.log('login onResult callback!')
                                api?.getPosterInformation(auth.currentUser!.uid)
                                .then((poster) => {
                                    if (poster !== undefined) {
                                        navigate('/feed') //navigates to feed when clicked. let's change 
                                        // so navigates to feed once it runs signInWithRedirecy
                                    } else {
                                        navigate('/register')
                                    }
                                })
                            }}></api.renderLogin>
                    )}
                    <div className={styles.register}>
                        <Link to='/register'>First time? Create an account!</Link>
                    </div>
                </div>
                <p>
                    You're part of an alpha version of Ours! If you find any issues,
                    please let us know at contact@helloours.com. Thank you!
                    <br></br>
                    - Madeline, Adrienne, Avery, and Ben
                </p>
            </div>
        </div>
    )
    
}

export default Login