import { isPropertySignature } from 'typescript'
import TextButton from '../../components/button/TextButton'

interface DummyLoginProps {
    callback: () => void
}

function DummyLogin(props: DummyLoginProps) {
    return <TextButton onClick={props.callback}>Sign in with Google</TextButton>
}

export default DummyLogin
