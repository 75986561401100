import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { BackendContext } from '../../api/backend'
import layoutStyles from '../../components/layout/LayoutStyles'
import PostCard from '../../components/post-card/PostCard'
import TopBar from '../../components/topbar/TopBar'
import PostModel from '../../models/Post'

function Post() {
    const api = useContext(BackendContext)
    const params = useParams()
    const [post, setPost] = useState<PostModel>()

    useEffect(() => {
        if (!params.id) return
        api?.getPost(params.id).then((post) => {
            if (!post) return
            setPost(() => post)
        })
    }, [api, params])

    return (
        <div className={layoutStyles.outer}>
            <TopBar />
            <div className={layoutStyles.inner}>
                {params.id ? (
                    post ? (
                        <>
                            <div className={layoutStyles.mainCentered}>
                                <PostCard post={post} />
                            </div>
                        </>
                    ) : (
                        <h1>Loading...</h1>
                    )
                ) : (
                    <p>Invalid or Expired URL</p>
                )}
            </div>
        </div>
    )
}

export default Post
