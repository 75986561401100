import { IconContext } from 'react-icons'
import { Link, To } from 'react-router-dom'
import styles from './IconButton.module.css'

interface IconButtonProps {
    to: To
    children: JSX.Element
}

function IconButton(props: IconButtonProps) {
    return (
        <IconContext.Provider value={{ className: styles.icon }}>
            <Link to={props.to} className={styles.iconContainer}>
                {props.children}
            </Link>
        </IconContext.Provider>
    )
}

export default IconButton
