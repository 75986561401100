import styles from './Chip.module.css'

interface ChipProps {
    children: string,
    active: boolean,
    callback: () => void
}

function Chip(props: ChipProps) {
    return (
        <li className={`${styles.chip} ${props.active ? styles.chipActive : ''}`} role="button" aria-pressed={props.active} onClick={props.callback}>
            {props.children}
        </li>
    )
}

export default Chip