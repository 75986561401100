import { FormEvent, useState } from 'react'
import FormSubmitButton from '../../../components/button/FormSubmitButton'
import TextButton from '../../../components/button/TextButton'
import Chip from '../../../components/chip/Chip'
import styles from './RegisterStep.module.css'
import layoutStyles from '../../../components/layout/LayoutStyles'

interface InterestsProps {
    callback: (interests: string[]) => void
}

function Interests(props: InterestsProps) {
    const possibleTags = [
        'Animals',
        'Board Games',
        'Books',
        'Brunch',
        'Concerts',
        'Cooking',
        'Drinks',
        'Food',
        'Gym',
        'Investing',
        'Museums',
        'Music',
        'Nature',
        'Puzzles',
        'Sports',
        'Travel',
    ]

    const [tags, setTags] = useState<Map<string, boolean>>(
        new Map(possibleTags.map((tag) => [tag, false]))
    )

    function onChipToggle(key: string) {
        setTags((oldMap) => new Map(oldMap).set(key, !oldMap.get(key)))
    }

    function submit() {
        props.callback(
            Array.from(tags.entries())
                .filter((e) => e[1])
                .map((e) => e[0])
        )
    }

    return (
        <div className={layoutStyles.main}>
            <h2>Interests (Select 3)</h2>
            {possibleTags.map((e, i) => (
                <Chip
                    key={e}
                    callback={() => onChipToggle(e)}
                    active={tags.get(e) as boolean}>
                    {e}
                </Chip>
            ))}
            <TextButton onClick={submit}>Next</TextButton>
        </div>
    )
}

export default Interests
