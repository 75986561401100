import React, { createContext } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import Register from './pages/register/Register'
import Login from './pages/login/Login'
import Feed from './pages/feed/Feed'
import DevBackend from './api/dummy/dummy'
import Backend from './api/backend'
import 'react-datepicker/dist/react-datepicker.css'

window.history.scrollRestoration = 'manual'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const backend = new DevBackend(1000, false, "")

root.render(<App backend={backend}></App>)
