import { FormEvent, useRef, useState } from 'react'
import FormSubmitButton from '../button/FormSubmitButton'
import styles from './ImageUploader.module.css'

interface ImageUploaderProps {
    callback: (file: File) => void
}

function ImageUploader(props: ImageUploaderProps) {
    const inputRef = useRef<HTMLInputElement>(null)
    const [file, setFile] = useState<File>()

    console.log('rendering uploader')

    function fileChanged() {
        if (inputRef.current?.files?.length) {
            setFile((inputRef.current?.files as FileList)[0])
        }
    }

    function onSubmit(e: FormEvent) {
        e.preventDefault()
        e.stopPropagation()
        if (file) {
            props.callback(file)
        }
    }

    return (
        <form className={styles.imageUploader} onSubmit={onSubmit}>
            <div className={styles.inputs}>
                <input
                    type="file"
                    ref={inputRef}
                    onChange={fileChanged}
                    className={styles.input}></input>
                {file ? (
                    <img
                        src={file ? URL.createObjectURL(file) : ''}
                        alt="File to upload"
                        className={styles.previewImage}></img>
                ) : null}
            </div>
            <FormSubmitButton>Submit Image</FormSubmitButton>
        </form>
    )
}

export default ImageUploader
