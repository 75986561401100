import { getAuth } from 'firebase/auth'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Backend, { BackendContext } from '../../api/backend'
import Post from '../../models/Post'
import Poster from '../../models/Poster'
import TextButton from '../button/TextButton'
import IconButton from '../icon-button/IconButton'
import PosterCard from '../poster-card/PosterCard'
import styles from './PostCard.module.css'

interface PostCardProps {
    post: Post
    onDismiss?: (id: string) => void
    interactive?: boolean
}

function PostCard(props: PostCardProps) {
    const missingProfileImage = "https://firebasestorage.googleapis.com/v0/b/ours-mvp-1.appspot.com/o/dev%2FprofileImages%2Fprofile%20img%20missing.png?alt=media&token=1da83256-803d-4499-bbb0-1d39166ee2f8"

    const api = useContext(BackendContext)
    const navigate = useNavigate()
    const [justRequested, setJustRequested] = useState(false)
    const [confirmationClosed, setConfirmationClosed] = useState(false)
    const [posterCardPoster, setPosterCardPoster] = useState<Poster | undefined>(undefined)
    const [posterCardOpened, setPosterCardOpened] = useState(false)

    const onAccept = useCallback(() => {
        getAuth().onAuthStateChanged((user) => {
            if (user) {
                api?.submitRequest(props.post.id)
                setJustRequested(true)
            } else {
                navigate('/login')
            }
        })

    }, [api, props])

    const onProfileClick = useCallback((poster: Poster | undefined) => {
        setPosterCardPoster(poster)
        setPosterCardOpened(true)
    }, [setPosterCardPoster, setPosterCardOpened])

    const closePosterCard = useCallback(() => {
        setPosterCardOpened(false)
    }, [setPosterCardOpened])

    const requested = props.post.requested || justRequested

    return (
        <>
            {posterCardOpened ? 
            <PosterCard 
                post={props.post} user={posterCardPoster} setClose={closePosterCard}
            /> 
            : null}
            <div className={styles.card}>
                <div className={styles.eventReq}>
                    <div 
                        className={styles.user}
                        onClick={() => onProfileClick(undefined)}>
                        <img
                            className={styles.profilePicture}
                            src={props.post.posterImageUrls[0]}
                            alt={'person'}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=missingProfileImage}}></img>
                        <p>
                            {props.post.posterName}
                        </p>
                    </div>
                    <div className={styles.request}>
                        {/* <p className={styles.day}>
                            {props.post.time.toLocaleDateString()}:
                        </p> */}
                        <p className={styles.description}>
                            {props.post.description}
                        </p>
                        {/* <p>
                            leaving at {props.post.time.toLocaleTimeString()}
                        </p> */}
                        {props.interactive && (
                            <>
                                <TextButton
                                    onClick={onAccept}
                                    smallMargins
                                    disabled={requested}>
                                    {requested ? 
                                        'Joined: text ' + props.post.posterName + ' at ' + props.post.posterNumber + ' to coordinate!': 
                                        'I\'m also going!'}
                                </TextButton>
                            </>
                        )}
                    </div>
                </div>
                {justRequested && !confirmationClosed && (
                    <div className={styles.confirmation}>
                        <div className={styles.confirmationClose}>
                            <IconButton
                                onClick={() => {
                                    setConfirmationClosed(true)
                                }}>
                                <MdClose />
                            </IconButton>
                        </div>
                        <h2>Great!</h2>
                        <p>
                            We will notify @{props.post.posterUsername} that you
                            want to go! Keep a lookout in your notifications for
                            updates.
                        </p>
                    </div>
                )}
                <div className={styles.requestingUsers}>
                    {props.post.requestingUsers.length != 0 && (
                        <p>classmates who are also going:</p>
                    )}
                    {props.post.requestingUsers.length == 0 && (
                        <p>click and join!</p>
                    )}
                    {props.post.requestingUsers.map((user) => (
                        <span 
                            key={props.post.id + "-" + user.id} 
                            className={styles.reqUser}
                            onClick={() => onProfileClick(user)}>
                            <img
                                className={styles.reqUserPicture}
                                src={user.imageUrls[0]}
                                alt={user.name}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src=missingProfileImage}}></img>
                            <p>
                                {user.name}
                            </p>
                        </span>
                    ))}
                </div>
            </div>
        </>
    )
}

export default PostCard
