import { useCallback, useContext, useEffect, useState } from 'react'

import Auth from './steps/Auth'
import TopBar from '../../components/topbar/TopBar'
import Profile from './steps/Profile'
import Interests from './steps/Interests'
import ImageUpload from './steps/ImageUpload'

import layoutStyles from '../../components/layout/LayoutStyles'
import Loading from './steps/Loading'
import Backend, { BackendContext } from '../../api/backend'
import Success from './steps/Success'
import Failure from './steps/Failure'
import { getAuth } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

enum RegistrationProgress {
    EMAIL,
    PROFILE,
    YEAR,
    INTERESTS,
    IMAGE_UPLOAD,
    LOADING,
    SUCCESS,
    FAILURE,
}

function Register() {
    const api = useContext(BackendContext) as Backend
    const [progress, setProgress] = useState(RegistrationProgress.EMAIL)
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [instagram, setInstagram] = useState('')
    const [snapchat, setSnapchat] = useState('')
    const [year, setYear] = useState('')

    const [interests, setInterests] = useState<string[]>([])

    const register = useCallback((profilePicture: string) => {
        api?.register({
            userId: getAuth().currentUser!.uid,
            username,
            name,
            year,
            interests,
            email: getAuth().currentUser!.email!,
            contact: {
                phoneNumber: phoneNumber,
                instagram: instagram,
                snapchat: snapchat,
            },
            profilePicture,
        })
            .then(() => setProgress(RegistrationProgress.SUCCESS))
            .catch(() => setProgress(RegistrationProgress.FAILURE))
    }, [
        api,
        getAuth,
        name,
        year,
        username,
        phoneNumber,
        snapchat,
        interests,
    ])

    let activeComponent = null
    switch (progress) {
        case RegistrationProgress.EMAIL:
            activeComponent = (
                <Auth
                    callback={() => setProgress(RegistrationProgress.PROFILE)}
                />
            )
            break
        case RegistrationProgress.PROFILE:
            activeComponent = (
                <Profile
                    callback={(
                        name: string,
                        username: string,
                        phone: string,
                        ig: string,
                        snap: string,
                        year: string
                    ) => {
                        setName(name)
                        setUsername(username)
                        setPhoneNumber(phone)
                        setInstagram(ig)
                        setSnapchat(snap)
                        setYear(year)
                        setProgress(RegistrationProgress.INTERESTS)
                    }}></Profile>
            )
            break
        case RegistrationProgress.INTERESTS:
            activeComponent = (
                <Interests
                    callback={(tags) => {
                        setInterests(tags)
                        setProgress(RegistrationProgress.IMAGE_UPLOAD)
                    }}></Interests>
            )
            break
        case RegistrationProgress.IMAGE_UPLOAD:
            activeComponent = (
                <ImageUpload
                    callback={(file) => {
                        setProgress(RegistrationProgress.LOADING)
                        const storage = getStorage()
                        const userImageRef = ref(storage, "dev/profileImages/" + getAuth().currentUser!.uid)
                        const upload = uploadBytes(userImageRef, file)
                        upload?.then((uploadRes) => {
                            getDownloadURL(uploadRes.ref).then((url) => {
                                register(url)
                            })
                        })
                    }}></ImageUpload>
            )
            break
        case RegistrationProgress.LOADING:
            activeComponent = <Loading />
            break
        case RegistrationProgress.SUCCESS:
            activeComponent = <Success />
            break
        case RegistrationProgress.FAILURE:
            activeComponent = <Failure />
            break
        default:
            activeComponent = (
                <Auth
                    callback={() => setProgress(RegistrationProgress.PROFILE)}
                />
            )
            break
    }

    return (
        <div className={layoutStyles.outer}>
            <TopBar></TopBar>
            <div className={layoutStyles.inner}>
                <h1>Create a profile</h1>
                <p>
                    Sign up with your Columbia/Barnard email!
                </p>
                {navigator.userAgent.includes("Instagram") && (
                    <p>
                        Unfortunately, Google login doesn't work in the Instagram in-app browser :(
                        do me a favor and go to www.helloours.com/register in Safari or Chrome or whatever
                        other browser you use! - ben(@helloours.com) 
                    </p>
                )}
                {navigator.userAgent.includes("Messenger") && (
                    <p>
                        Unfortunately, Google login doesn't work in the Messenger in-app browser :(
                        do me a favor and go to www.helloours.com/register in Safari or Chrome or whatever
                        other browser you use! - ben(@helloours.com) 
                    </p>
                )}
                {navigator.userAgent.includes("Facebook") && (
                    <p>
                        Unfortunately, Google login doesn't work in the Facebook in-app browser :(
                        do me a favor and go to www.helloours.com/register in Safari or Chrome or whatever
                        other browser you use! - ben(@helloours.com) 
                    </p>
                )}
                {navigator.userAgent.includes("Snapchat") && (
                    <p>
                        Unfortunately, Google login doesn't work in the Snapchat in-app browser :(
                        do me a favor and go to www.helloours.com/register in Safari or Chrome or whatever
                        other browser you use! - ben(@helloours.com) 
                    </p>
                )}
                {(
                    !navigator.userAgent.includes("instagram") &&
                    !navigator.userAgent.includes("messenger") &&
                    !navigator.userAgent.includes("facebook") &&
                    !navigator.userAgent.includes("snapchat")
                ) && activeComponent}
            </div>
        </div>
    )
}

export default Register
