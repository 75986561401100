import { FormEvent, useCallback, useContext, useEffect, useState } from 'react'
import { MdHome } from 'react-icons/md'
import { BackendContext } from '../../api/backend'
import LinkIconButton from '../../components/icon-button/LinkIconButton'
import styles from './Create.module.css'
import layoutStyles from '../../components/layout/LayoutStyles'
import TopBar from '../../components/topbar/TopBar'
import DatePicker from 'react-datepicker'
import FormSubmitButton from '../../components/button/FormSubmitButton'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase-config'

function Create() {
    let api = useContext(BackendContext)
    let navigate = useNavigate()
    let [description, setDescription] = useState<string>('')
    let [time, setTime] = useState<Date>(new Date())

    // Require login
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            user ? Function.prototype() : navigate('/login')
        })
    })

    function submitCallback(e: FormEvent) {
        e.preventDefault()
        api?.submitPost(description, time)
        navigate('/feed')
    }

    return (
        <div className={layoutStyles.outer}>
            <TopBar>
                <LinkIconButton to="/feed">
                    <MdHome />
                </LinkIconButton>
            </TopBar>
            <div className={layoutStyles.inner}>
                <h1>Create Post</h1>
                <div className={layoutStyles.main}>
                    <div className={styles.form}>
                        <form onSubmit={submitCallback}>
                            <label htmlFor="description">Description</label>
                            <textarea
                                id="description"
                                placeholder="e.g. Columbia -> JFK via Uber leaving at around 10 AM :)"
                                onChange={(e) =>
                                    setDescription(e.target.value)
                                }></textarea>
                            {/* <label>I want to leave at…</label>
                            <DatePicker
                                showTimeInput
                                showTimeSelect
                                selected={time}
                                onChange={(date) => date && setTime(date)}
                                dateFormat='p, P'
                            /> */}
                            <FormSubmitButton>Submit</FormSubmitButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Create
