// Backend

import Post from '../models/Post'
import Poster from '../models/Poster'
import Notification, { Acceptance } from '../models/Notification'
import React, { createContext } from 'react'
import Registration from '../models/Registration'

interface LoginProps {
    /**
     * Called when a login method succeeds or fails.
     */
    onResult: (success: boolean) => void
}

//madeine adding 
interface LogoutProps{
    onResult: (success: boolean) => void
}

export { type LoginProps, type LogoutProps }

abstract class Backend {
    /**
     * Initializes the backend.
     */
    abstract initialize(): Promise<boolean>
    /**
     * Whether the current user is authenticated.
     */
    abstract authenticated: boolean
    /**
     * Registers an already authenticated user, creating their profile.
     * @param data - Registration information
     */
    abstract register(data: Registration): Promise<void>
    /**
     * Returns the public information of a poster/user.
     * @param userId - The poster's ID.
     */
    abstract getPosterInformation(userId: string): Promise<Poster | undefined>
    /**
     * Publishes a new post.
     * @param description - The location which the event ends at.
     * @param time - A Date object representing the beginning of the event.
     */
    abstract submitPost(
        description: string,
        time: Date
    ): Promise<void>
    /**
     * Retrieves a post from a post ID.
     * @param postId - The post's ID.
     */
    abstract getPost(postId: string): Promise<Post | undefined>
    /**
     * Lists the posts visible to the authenticated user.
     * @param page - The page of posts.
     * @param amount - The amount of posts on a page.
     */
    abstract listPosts(page: number, amount: number): Promise<Post[]>
    /**
     * Request to join a published event. Requires the original poster to confirm using `acceptRequest`.
     * @param postId - The relevant post.
     */
    abstract submitRequest(postId: string): Promise<void>
    /**
     * Lists the authorized user's notifications.
     */
    abstract listNotifications(): Promise<Notification[]>
    /**
     * Accepts a join request notification.
     * @param notificationId - the join request's ID.
     * @returns the match notification that shows up in the accepter's feed.
     */
    abstract acceptRequest(notificationId: string): Promise<Acceptance | undefined>
    /**
     * Rejects a join request notification.
     * @param notificationId - the join request's ID.
     */
    abstract rejectRequest(notificationId: string): Promise<void>
    /**
     * JSX function which renders login buttons for each available login provider.
     */
    abstract renderLogin: (props: LoginProps) => JSX.Element

    abstract renderLogout: (props: LogoutProps) => JSX.Element
}

export default Backend

// Nothing should ever be outside of the provider.
const BackendContext = createContext<Backend | null>(null)

export { BackendContext }
