import { IconContext } from 'react-icons'
import styles from './IconButton.module.css'

interface IconButtonProps {
    children: JSX.Element
    onClick: () => void
}

function IconButton(props: IconButtonProps) {
    return (
        <IconContext.Provider value={{ className: styles.icon }}>
            <button className={styles.iconContainer} onClick={props.onClick}>
                {props.children}
            </button>
        </IconContext.Provider>
    )
}

export default IconButton
