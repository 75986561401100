import { useCallback, useEffect } from 'react'
import { MdClose } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase-config'
import Post from '../../models/Post'
import Poster from '../../models/Poster'
import IconButton from '../icon-button/IconButton'
import styles from './PosterCard.module.css'

interface PosterCardProps {
    post: Post
    user: Poster | undefined
    setClose: (closed: boolean) => void
}

function PosterCard(props: PosterCardProps) {
    const navigate = useNavigate()

    // Require login
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            user ? Function.prototype() : navigate('/login')
        })
    })

    const onClose = useCallback(() => {
        props.setClose(true)
    }, [props])

    return (
        <>
        {!props.user && (
            <div className={styles.cardBackground} onClick={onClose}>
                <div className={styles.card} onClick={(e) => {e.stopPropagation()}}>
                    <div className={styles.cardClose}>
                        <IconButton
                            onClick={onClose}>
                            <MdClose className={styles.closeIcon} />
                        </IconButton>
                    </div>

                    <div className={styles.user}>
                        <img
                            className={styles.profilePictures}
                            src={props.post.posterImageUrls[0]}
                            alt={'person'}></img>
                        <p>
                            {props.post.posterName}
                        </p>
                        <div className={styles.interests}>
                            {props.post.posterInterests?.map((e) => (
                                <span key={e} className={styles.interest}>
                                    {e}
                                </span>
                            ))}
                        </div>
                        <div className={styles.bio}>
                            <p>
                                {props.post.posterBio}
                            </p>
                        </div>
                        <div className={styles.year}>
                            <p>
                                {props.post.posterYear}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {props.user && (
            <div className={styles.cardBackground} onClick={onClose}>
                <div className={styles.card} onClick={(e) => {e.stopPropagation()}}>
                    <div className={styles.cardClose}>
                        <IconButton
                            onClick={onClose}>
                            <MdClose className={styles.closeIcon} />
                        </IconButton>
                    </div>

                    <div className={styles.user}>
                        <img
                            className={styles.profilePictures}
                            src={props.user.imageUrls[0]}
                            alt={'person'}></img>
                        <p>
                            {props.user.name}
                        </p>
                        <div className={styles.interests}>
                            {props.user.interests?.map((e) => (
                                <span key={e} className={styles.interest}>
                                    {e}
                                </span>
                            ))}
                        </div>
                        <div className={styles.bio}>
                            <p>
                                {props.user.bio}
                            </p>
                        </div>
                        <div className={styles.year}>
                            <p>
                                {props.user.year}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )
}

export default PosterCard
