import React, { createContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home/Home'
import Register from './pages/register/Register'
import Login from './pages/login/Login'
import Feed from './pages/feed/Feed'
import './App.css'
import Backend, { BackendContext } from './api/backend'
import Notifications from './pages/notifications/Notifications'
import Post from './pages/post/Post'
import Create from './pages/create/Create'
import 'firebase/compat/firestore';

interface AppProps {
    backend: Backend
}

function App(props: AppProps) {
    let [backendReady, setBackendReady] = useState(false)

    useEffect(() => {
        props.backend.initialize().then((result) => setBackendReady(result))
    }, [props.backend, setBackendReady])

    return (
        <React.StrictMode>
            <BackendContext.Provider
                value={backendReady ? props.backend : null}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/feed" element={<Feed />} />
                        <Route path="/create" element={<Create />} />
                        <Route
                            path="/notifications"
                            element={<Notifications />}
                        />
                        <Route path="/post/:id" element={<Post />} />
                    </Routes>
                </BrowserRouter>
            </BackendContext.Provider>
        </React.StrictMode>
    )
}

export default App
