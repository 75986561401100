import styles from './TopBar.module.css'

interface TopBarProps {
    children?: any
}

function TopBar(props: TopBarProps) {
    return <div className={styles.topbar}>{props.children}</div>
}

export default TopBar
