import styles from './Button.module.css'

interface TextButtonProps {
    children: String
    onClick: () => void
    smallMargins?: boolean
    shrink?: boolean
    disabled?: boolean
}

function TextButton(props: TextButtonProps) {
    return (
        <button
            className={`
                ${styles.button} 
                ${props.smallMargins ? styles.smallMargins : ''} 
                ${props.shrink ? styles.shrink : ''} 
                ${props.disabled ? styles.disabled : ''}
            `}
            onClick={props.onClick}>
            {props.children}
        </button>
    )
}

export default TextButton
