import MatchedUser from '../../models/MatchedUser'
import { Acceptance } from '../../models/Notification'
import styles from './MatchedDetails.module.css'
import { FaInstagram, FaSnapchatGhost } from 'react-icons/fa'
import { MdClose, MdEmail, MdPhone } from 'react-icons/md'
import IconButton from '../icon-button/IconButton'

interface MatchedDetailsProps {
    acceptance: Acceptance
    closeCallback: () => void
}

function MatchedDetails(props: MatchedDetailsProps) {
    const n = props.acceptance
    return (
        <div className={styles.outer}>
            <div className={styles.close}>
                <IconButton onClick={props.closeCallback}>
                    <MdClose />
                </IconButton>
            </div>
            <h2>Successful Match!</h2>
            <p>
                You and {n.match.name} matched!
            </p>
            <p>Description: {n.description}</p>
            <div key={n.match.username}>
                <h4>Contact {n.match.name}:</h4>
                {n.match.contact["phoneNumber"] && (
                    <p className={styles.contactLine}>
                        <MdPhone className={styles.contactIcon} />
                        <span>{n.match.contact["phoneNumber"]}</span>
                    </p>
                )}
                {n.match.contact["email"] && (
                    <p className={styles.contactLine}>
                        <MdEmail className={styles.contactIcon} />
                        <span>{n.match.contact["email"]}</span>
                    </p>
                )}
                {n.match.contact["instagram"] && (
                    <p className={styles.contactLine}>
                        <FaInstagram className={styles.contactIcon} />
                        <span>{n.match.contact["instagram"]}</span>
                    </p>
                )}
                {n.match.contact["snapchat"] && (
                    <p className={styles.contactLine}>
                        <FaSnapchatGhost className={styles.contactIcon} />
                        <span>{n.match.contact["snapchat"]}</span>
                    </p>
                )}
            </div>
        </div>
    )
}
export default MatchedDetails
