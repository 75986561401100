import styles from './Home.module.css'
import LinkButton from '../../components/button/LinkButton'

// import { useContext } from 'react'
// import { BackendContext } from '../../api/backend'
// import layoutStyles from '../../components/layout/LayoutStyles'

function Home() {
    // const backend = useContext(BackendContext)

    return (
        <div className={styles.outer}>
            <div className={styles.inner}>
                <div className={styles.headerBox}>
                    <h1 className={styles.title}>Welcome to Ours!</h1>
                    <p>skip the wait & split a ride with our classmates :)</p>
                </div>
                <div className={styles.buttonBox}>
                    <LinkButton to="/register">Create a profile</LinkButton>
                    <p>
                        Please use your Columbia/Barnard email!
                    </p>
                    <LinkButton to="/login">Log in</LinkButton>
                </div>
                <div className={styles.noteBox}>
                    <p>
                        You're part of an alpha version of Ours! If you find any issues,
                        please let us know at contact@helloours.com. Thank you!
                        <br></br>
                        - Madeline, Adrienne, Avery, and Ben
                    </p>
                    {/* <p>
                        Give us a minute — we're sorting some things out and will
                        be back in a day :)
                        <br></br>
                        Thanks for being part of our alpha testing!
                        - Madeline, Adrienne, Avery, and Ben
                    </p> */}
                </div>
            </div>
        </div>
        
    )
}

export default Home
