// Import the functions you need from the SDKs you need
import  { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import "firebase/firestore"; // access firestore database service
import { initializeFirestore } from "firebase/firestore";
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, User} from "firebase/auth"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAhwvBp9uHMhWAfbvxMPNvdW6hM604ZLq4",
  authDomain: "helloours.com",
  projectId: "ours-mvp-1",
  storageBucket: "ours-mvp-1.appspot.com",
  messagingSenderId: "266185462860",
  appId: "1:266185462860:web:ead01254d533a0bef850e9",
  measurementId: "G-W6ZFTHJ3ZZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);


const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' }); // need? 

export {app, db, auth, provider};