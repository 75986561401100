import ImageUploader from '../../../components/image-uploader/ImageUploader'
import styles from './RegisterStep.module.css'
import layoutStyles from '../../../components/layout/LayoutStyles'

interface ImageUploadProps {
    callback: (file: File) => void
}

function ImageUpload(props: ImageUploadProps) {
    return (
        <div className={layoutStyles.main}>
            <ImageUploader callback={props.callback}></ImageUploader>
        </div>
    )
}

export default ImageUpload
