import layoutStyles from '../../../components/layout/LayoutStyles'
import LinkButton from '../../../components/button/LinkButton'

function Failure() {
    return (
        <div className={layoutStyles.main}>
            <h2>Something went wrong</h2>
            <LinkButton to="/">Back to home</LinkButton>
        </div>
    )
}

export default Failure
