import NotificationModel, { Acceptance } from '../../models/Notification'
import styles from './Notification.module.css'
import { MdCheck, MdClose } from 'react-icons/md'
import { useCallback, useContext, useState } from 'react'
import { BackendContext } from '../../api/backend'
import TextButton from '../button/TextButton'
import MatchedDetails from './MatchedDetails'

interface NotificationProps {
    notification: NotificationModel
    deleteCallback: (id: string) => void
}

function Notification(props: NotificationProps) {
    const api = useContext(BackendContext)
    const [expanded, setExpanded] = useState(false)
    const [justRejected, setJustRejected] = useState(false)
    const [n, setN] = useState(props.notification)

    const deleteCallback = props.deleteCallback

    const accept = useCallback(() => {
        api?.acceptRequest(n.id).then((accept) => {
            if (accept) {
                setN(accept)
            }
        })
    }, [api, setN, n.id])

    const decline = useCallback(() => {
        setJustRejected(true)
        api?.rejectRequest(n.id)
    }, [api, n.id])

    const close = useCallback(() => {
        deleteCallback(n.id)
    }, [deleteCallback, n.id])

    function toggleDetails() {
        setExpanded((expanded) => !expanded)
    }

    let buttons
    let column
    let description // Using an element in case we want to make usernames clickable at some point
    switch (n.type) {
        case 'offer': {
            buttons = (
                <>
                    <MdCheck
                        className={`${styles.button} ${styles.accept}`}
                        onClick={accept}
                    />
                    <MdClose
                        className={`${styles.button} ${styles.decline}`}
                        onClick={decline}
                    />
                </>
            )
            // E.g. "@User1 wants to go to Trader Joes!"
            description = (
                <>
                    @{n.username} wants to go to {n.description}!
                </>
            )
            column = false
            break
        }

        case 'acceptance': {
            buttons = (
                <TextButton onClick={toggleDetails} shrink>
                    Details
                </TextButton>
            )
            column = true
            // E.g. "You, @User1, and @User2 want to go to Trader Joes!"
            description = (
                <>
                    You and @{n.match.username} are a match for {n.description}!
                </>
            )
            break
        }
    }

    return (
        <>
            {!justRejected && (
                <>
                    <div className={styles.notification}>
                        <img
                            src={props.notification.iconUrl}
                            className={styles.icon}
                            alt=""
                        />
                        <div
                            className={`${styles.details} ${
                                column ? styles.column : ''
                            }`}>
                            <p className={styles.text}>{description}</p>
                            <div className={styles.buttons}>{buttons}</div>
                        </div>
                    </div>
                    {expanded && (
                        <MatchedDetails
                            acceptance={n as Acceptance}
                            closeCallback={toggleDetails}
                        />
                    )}
                </>
            )}
            {justRejected && (
                <div className={styles.notification}>
                        <div
                            className={`${styles.details} ${
                                column ? styles.column : ''
                            }`}>
                            <p className={styles.text}>
                                {n.type == 'offer' ? 
                                 "Declined @" + n.username + "'s request." : 
                                 "This message should not show up!"}
                            </p>
                            <div className={styles.buttons}>
                                <MdClose
                                    className={`${styles.button} ${styles.close}`}
                                    onClick={close}
                                />
                            </div>
                        </div>
                </div>
            )}
        </>
    )
}

export default Notification
